<template>
  <div class="content_box">
    <div class="inner_max">
      <component :is="currentComponent"></component>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentComponent() {
      return () => this.$platform.pageLoader(this.regulator, 'deposit/CreditCard');
    }
  }
};
</script>
